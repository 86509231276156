import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/weekly/weekly/packages/website/src/components/Layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  fragment ToolInfo on WESTACK_Tool {
    about
    id
    key
    name
    source
    website
    link
    iconURL(size: 200)
  }
  {
    IPinfo_Cli: westack {
      tool(id: "ipinfo-cli") {
        ...ToolInfo
      }
    }
    Chanify: westack {
      tool(id: "chanify") {
        ...ToolInfo
      }
    }
    The_Algorithms: westack {
      tool(id: "the-algorithms") {
        ...ToolInfo
      }
    }
    Flipper: westack {
      tool(id: "flipper") {
        ...ToolInfo
      }
    }
    The_Art_OF_CL: westack {
      tool(id: "the-art-of-command-line") {
        ...ToolInfo
      }
    }
    Git_Split_Diffs: westack {
      tool(id: "git-split-diffs") {
        ...ToolInfo
      }
    }
    Hammerspoon: westack {
      tool(id: "hammerspoon") {
        ...ToolInfo
      }
    }
    Slidev: westack {
      tool(id: "slidev") {
        ...ToolInfo
      }
    }
    Bitwarden: westack {
      tool(id: "bitwarden") {
        ...ToolInfo
      }
    }
    Figma: westack {
      tool(id: "figma") {
        ...ToolInfo
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tool = makeShortcode("Tool");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{props.pageContext.frontmatter.title}</h1>
    <Tool tool={props.data.IPinfo_Cli.tool} mdxType="Tool" />
    <Tool tool={props.data.Chanify.tool} mdxType="Tool" />
    <Tool tool={props.data.The_Algorithms.tool} mdxType="Tool" />
    <Tool tool={props.data.Flipper.tool} mdxType="Tool" />
    <Tool tool={props.data.The_Art_OF_CL.tool} mdxType="Tool" />
    <Tool tool={props.data.Git_Split_Diffs.tool} mdxType="Tool" />
    <Tool tool={props.data.Hammerspoon.tool} mdxType="Tool" />
    <Tool tool={props.data.Slidev.tool} mdxType="Tool" />
    <Tool tool={props.data.Bitwarden.tool} mdxType="Tool" />
    <Tool tool={props.data.Figma.tool} mdxType="Tool" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      